$primary: #005083; /* MAIN COLOR */
$secondary: #d6b443; /* SECONDARY COLOR */
$trm: #C4CED3; /*TRIM COLOR*/
$blk: #333; /* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */
$wht: #fafafa; 
$border-radius: 0px; /* USE THIS TO SET THE BORDER RADIUS FOR BUTTONS */
$footerLinks: #333;


.flash {
	display:none;
}

body {background: $blk !important;}

p, ol, ul {font-family: 'Allan', cursive;
		   color: $wht;}

footer a,footer p {
	color: $footerLinks;
	font-family: arial, sans-serif;
	font-size: 1em !important;

	&:hover, &:focus, &:active {
		color: $footerLinks;

	}
}

a {
	// font-family: 'Allan', cursive;
   font-size: 1.5em;
}



h1 {font-family: 'Merienda One', cursive;
	font-size: 4em;
	color: $wht;

		@media (max-width: 767px) {
			font-size: 2.5em;
		}
	
	}

h2 {font-family: 'Merienda One', cursive;
	font-size: 2.5em;
	color: $wht;
	}

h3 {font-family: 'Merienda One', cursive;
	font-size: 1.75em;
	color: $wht;}


/** NAV START **/

.navbar-default {
	background-color: $blk;
}

nav.navbar {
	z-index: 1000;
	border: none;
	border-radius: $border-radius;
	
	.navbar-nav {

	    > li > a {
			text-align: center;
			height: 80px;
			display: flex;
			align-items: center;
			color: $wht;


			@media (max-width: 767px) {
			    height: 29px;
			    display: inline-block;
    			font-size: 14px;
				font-family: arial,sans-serif;
			}
		}
	}
}


.navbar-default .navbar-nav>li>a:focus, {
	color: $wht;
}
.navbar-default .navbar-nav>li>a:hover {
	color: $secondary;
} 

.navbar-right {
	margin-top: 10px;
	font-size: 1.5em;
			@media (max-width: 767px) {
				margin-top: 0px;
				padding-top: 0px;
			}
}


.navbar-toggle {
    margin: 20px 15px 8px 0px;
    border: 1px solid transparent;
    border-radius: 4px;
	
	@media (max-width: 388px) {
		margin-top: 14px;
	}
}


/** LOGIN FORM **/
@mixin btn1 {
	margin: 0 auto;
	border: none;
	border-radius: $border-radius;
	background: $secondary;
	color: $blk;
	padding: 0.5em 2em;
	font-size: 1.25em;
	font-weight: bold;
	text-align: center;
	text-transform: uppercase;
	letter-spacing: 1px;

	&:hover {
		background: lighten($primary,10%);
		color: lighten($wht, 10%);
		box-shadow: 0px 0px 3px lighten($blk,10%);
	}
}


@mixin btn2 {
	@include btn1;
	background: darken($wht, 5%);
	color: $primary;
	border: none;

	&:hover {
		background: transparent;
		color: $primary;
		border: none;
	}
}


.modal-dialog {
	max-width: 300px;
	text-align: center;
	margin: 6em auto;
	
	.close {display: none;}

	.modal-content {
		color: $blk;
		
		h2 {
			text-align:center;
		}
	}

	input {
		border: none;
		border-bottom: 1px solid darken($primary,20%);
		text-align: center;
	}
	
	button {
		background: $secondary;
		color: $blk;
		display: block;
		border:none;
		padding: 1em 2em;
		margin: 0 auto;

		&:hover {
			background: transparent;
			color: #fff;
			box-shadow: none;
			text-shadow: 0px 0px 3px $blk;
			border:none;
		}
	}
	
	input[type="submit"] {
		@include btn1;
		display: block;
		width: 75%;	
	}

}

.modal-header, .modal-footer {
	background: $secondary;
	color: $wht;
}



.modal-open {
  overflow: auto; 
  padding-right: 0px !important;
}


input#username {
	margin-bottom: 20px;
}


/** END LOGIN FORM **/
footer {
	padding: 50px 0px 20px;
	background: $secondary !important;
	color: $footerLinks;
	font-size: 1.35em;

	a {
		
		white-space: nowrap;
		background: transparent !important;

		&:hover{
			
			outline: 0 !important;
 			text-decoration: none;
		}

		&:focus {
			
			outline: 0 !important;
 			text-decoration: none;
		}
	}
}


input#username, input#password {
	width: 100%;
}


@media (max-width: 1024) {
	input#username, input#password {
  		font-size: 16px;
	}
}


select {
    -moz-appearance: none;
    text-indent: 0.01px;
    text-overflow: '';
}


a,a:hover,a:focus,a:active {
  outline: 0 !important;
}


.logo {
	max-width: 250px;
	padding: 0.5em;
	background: transparent;
}


@media(max-width: 767px) {
	.logo {
		max-width: 175px;
	}

    .navbar-header a {
        float: left;
    } 
}

.fade {background: rgba(0,0,0,.5) !important;}

/* ========= BUTTONS ============ */

.btn-download,
.btn-download:focus,
.btn-download:active,
.btn-download:visited
{
    background-color: $secondary;
    border-color: $wht;
	   color: $blk;
padding: 10px 20px;
    font-size: 15px;
    font-weight: bold;
    text-transform: uppercase;
    border-radius: 12px;
    transition: all 1s;
    -webkit-transition: all 1s;
    -moz-transition: all 1s;
     -o-transition: all 1s;
	 box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.btn-download:hover {
    background-color: $primary;
    color: $wht;

}

.btn-download {
    margin-bottom: 15px !important;
    display: block;
    margin: 0 auto;
    max-width: 175px;
	margin-top: 50px;


	@media (max-width: 767px) {

		margin-top: 10px;
	}



	@media (max-width: 1024px) {

		margin-top: 10px;
	}
}




/* ======= HEADER ======= */

.header {
		background: url('../img/banner.jpg');
		 background-repeat: no-repeat;
		 background-position: right top;
		 background-size: cover;
		 
		 
		 @media (max-width: 1024px) {
			 background-position: 85% 10%;
		 }

		 @media (max-width: 767px) {
			 background-position: 85% 10%;
		 }
		 
}




.header-overlay {background: rgba(255, 255, 255, 0.65);
				 padding: 150px 0px;
				 
				 @media (max-width: 767px) {
					 padding: 50px 0px;
				 }

}


.header h1 {color: $blk;
			text-shadow: 2px 2px $trm;}



.header p {color: $blk;
		   font-size: 2em !important;
		   letter-spacing: 1px;}

/* ======= SECTION ONE ======= */


.section-one {padding: 50px 0px;}

.section-one h1 {color: $wht;}

.section-one h2 {color: $wht;
				 margin-top: 50px;}

.section-one p {color: $wht;
		   		font-size: 1.5em !important;
		   		letter-spacing: 1px;}

.section-one i {color: $wht;
				font-size: 150px;
				margin-top: 40px;}

	
/* ======= SECTION TWO ======= */

.section-two .one {
	background: url('../img/img1.jpg');
	background-repeat: no-repeat;
	background-position: 50% 50%;
	background-size: cover;
	min-height: 50vh;

	@media (max-width: 767px) {
		background-position: 70% 50%;
	}
}

.section-two .two {
	background: url('../img/img2.jpg');
	background-repeat: no-repeat;
	background-position: 50% 50%;
	background-size: cover;
	min-height: 50vh;
	
	@media (max-width: 767px) {
		background-position: 45% 50%;
	}
}

/* ======= SECTION THREE ======= */


.section-three {padding: 50px 0px;}

.section-three h1 {color: $wht;}

.section-three h2 {color: $wht;
				 margin-top: 50px;}

.section-three p {color: $wht;
		   		font-size: 1.5em !important;
		   		letter-spacing: 1px;}

.section-three i {color: $wht;
				font-size: 150px;
				margin-top: 40px;}

.section-three img {width: 100%;
					display: block;
					margin: 0px auto;
					max-width: 300px;}

/* ======= SECTION FOUR ======= */

.section-four {
	padding: 25px 0px;
}

.section-four h1 {color: $wht; 

}

.section-four p {color: $wht;
		   		font-size: 1.5em !important;
		   		letter-spacing: 1px;}


.section-four .row {margin-left: 0px;
	 margin-right: 0px;}



/* ======= SECTION TWO B ======= */

.section-two-B .one {
	background: url('../img/img3.jpg');
	background-repeat: no-repeat;
	background-size: cover;
	min-height: 50vh;

	@media (max-width: 767px) {
		background-position: 60% 50%;
	}
}

.section-two-B .two {
	background: url('../img/img4.jpg');
	background-repeat: no-repeat;
	background-size: cover;
	min-height: 50vh;


	@media (max-width: 767px) {
		background-position: 35% 50%;
	}
}

hr {
	border:3px solid $secondary;
	border-radius: 4px;
}


.top-pad {
	background: $wht;
	padding: 1em 4em;
	h1,h2,h3,p,ol,ul,li {
		color: $blk !important;
		font-family: arial, sans-serif;
	}
}